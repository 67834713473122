import React from "react"
import { observer } from "mobx-react-lite"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import Image from "@components/ui/Image/Image"
import ImageTopper from "@components/ui/Image/Topper"
import { useFormikContext } from "formik"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import AssetImagesSidebar from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar"
import styles from "./Assets.module.scss"

const Images = observer(() => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { values } = useFormikContext<EditAssetGroupFormValues>()
  const images = values.images && values.images.length ? values.images : []
  return (
    <div className={styles.section}>
      <Typography className={styles.headTitle} type="h2">
        Images
      </Typography>
      <Typography type="subtitle" color="gray">
        Add up to 20 images
      </Typography>
      <div className={styles.images}>
        {images.slice(0, 3).map((img, i) => (
          <Image src={img.url} className={styles.image} key={`${i + 1}-logo`} />
        ))}
        <ImageTopper className={styles.image} message="+">
          <Image key="last-image" />
        </ImageTopper>
      </div>

      <Button
        color="primary"
        before={<Icon name="plus" key="plus" />}
        variant="ghost"
        className={styles.buttonIcon}
        onClick={() => setIsOpen(true)}
      >
        Image
      </Button>

      <AssetImagesSidebar isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
})

export default Images
