import React, { useMemo } from "react"
import clsx from "clsx"

import { initArrayByLength, randomNumber } from "@utils/numberUtils"

import styles from "./Skeleton.module.sass"

export type SkeletonProps = {
  count: number
  className?: string
  lineClassName?: string
}

export const Skeleton: React.FC<SkeletonProps> = ({
  count,
  className,
  lineClassName,
}) => {
  const lines = useMemo(
    () => initArrayByLength(count).map(() => randomNumber(80, 20)),
    [count]
  )

  return (
    <div className={clsx(styles.root, className)}>
      {lines.map((line, idx) => (
        <p
          className={clsx(styles.line, lineClassName)}
          style={{ width: `${line}%` }}
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
        />
      ))}
    </div>
  )
}

export default Skeleton
