import _sortBy from "lodash/sortBy"
import {
  AdsReportType,
  CampaignReportDataType,
  CampaignReportType,
  KeywordEntityType,
  KeywordReportType,
  PMaxProductReportType,
  QueryReportType,
} from "@framework/types/account"
import { SortByType } from "@framework/types/types"

export const sortCampaignList = (
  campaignList: CampaignReportDataType[],
  sortBy?: SortByType
) => {
  const sortedList =
    !sortBy?.value || sortBy.value === "label"
      ? _sortBy(campaignList, "Campaign.Name")
      : _sortBy(campaignList, (item) => {
          const value =
            item.Campaign[(sortBy.value || "Cost") as keyof CampaignReportType]
          if (value != null && typeof value === "object" && "Value" in value)
            return value.Value
          return value
        })

  return sortBy?.direction ? sortedList : sortedList.reverse()
}

export const searchCampaigns = (
  campaignList: CampaignReportDataType[],
  query: string
) => {
  if (!query) return [...campaignList]

  const lowerQuery = query.toLowerCase()
  return campaignList.filter((item) =>
    item.Campaign.Name.toLowerCase().includes(lowerQuery)
  )
}

export const sortCampaignReports = <
  T extends CampaignReportType = CampaignReportType
>(
  adGroupsList: T[],
  sortBy?: SortByType
): T[] => {
  const sortedList =
    !sortBy?.value || sortBy.value === "label"
      ? _sortBy(adGroupsList, "Name")
      : _sortBy(adGroupsList, (item) => {
          const value =
            item[(sortBy.value || "Cost") as keyof CampaignReportType]
          if (value != null && typeof value === "object" && "Value" in value)
            return value.Value
          return value
        })

  return sortBy?.direction ? sortedList : sortedList.reverse()
}

export const searchCampaignReports = (
  campaignList: CampaignReportType[],
  query: string
) => {
  if (!query) return [...campaignList]

  const lowerQuery = query.toLowerCase()
  return campaignList.filter((item) =>
    item.Name.toLowerCase().includes(lowerQuery)
  )
}

export const sortKeywordReports = <
  T extends KeywordReportType = KeywordReportType
>(
  adGroupsList: T[],
  sortBy?: SortByType
): T[] => {
  const sortedList =
    !sortBy?.value || sortBy.value === "label"
      ? _sortBy(adGroupsList, (it) => it.Criteria)
      : _sortBy(adGroupsList, (item) => {
          const value =
            item[(sortBy.value || "Cost") as keyof KeywordReportType]
          if (value != null && typeof value === "object" && "Value" in value)
            return value.Value
          return value
        })

  return sortBy?.direction ? sortedList : sortedList.reverse()
}

export const sortAdReports = (
  adGroupsList: AdsReportType[],
  sortBy?: SortByType
): AdsReportType[] => {
  const sortedList =
    !sortBy?.value || sortBy.value === "label"
      ? _sortBy(adGroupsList, (it) => it.Headline)
      : _sortBy(adGroupsList, (item) => {
          const value = item[(sortBy.value || "Cost") as keyof AdsReportType]
          if (value != null && typeof value === "object" && "Value" in value)
            return value.Value
          return value
        })

  return sortBy?.direction ? sortedList : sortedList.reverse()
}

export const sortQueriesReports = (
  adGroupsList: QueryReportType[],
  sortBy?: SortByType
): QueryReportType[] => {
  const sortedList =
    !sortBy?.value || sortBy.value === "label"
      ? _sortBy(adGroupsList, (it) => it.Text)
      : _sortBy(adGroupsList, (item) => {
          if (sortBy.value === "MatchTypes") return item.MatchTypes?.[0]

          const value = item[(sortBy.value || "Cost") as keyof QueryReportType]
          if (value != null && typeof value === "object" && "Value" in value)
            return value.Value
          return value
        })

  return sortBy?.direction ? sortedList : sortedList.reverse()
}

export const searchKeywords = (
  campaignList: KeywordEntityType[],
  query: string
) => {
  if (!query) return [...campaignList]

  const lowerQuery = query.toLowerCase()
  return campaignList.filter((item) =>
    item.Criteria.toLowerCase().includes(lowerQuery)
  )
}

export const searchAds = (campaignList: AdsReportType[], query: string) => {
  if (!query) return [...campaignList]

  const lowerQuery = query.toLowerCase()
  return campaignList.filter((item) =>
    item.Headline.toLowerCase().includes(lowerQuery)
  )
}

export const searchQueries = (list: QueryReportType[], query: string) => {
  if (!query) return [...list]

  const lowerQuery = query.toLowerCase()

  return list.filter((item) => item.Text.toLowerCase().includes(lowerQuery))
}

export const sortCampaignReportsAsync = async <
  T extends CampaignReportType = CampaignReportType
>(
  adGroupsList: T[],
  sortBy?: SortByType
): Promise<T[]> => {
  const res = await sortCampaignReports(adGroupsList, sortBy)
  return res
}

export const sortKeywordReportsAsync = async <
  T extends KeywordReportType = KeywordReportType
>(
  adGroupsList: T[],
  sortBy?: SortByType
): Promise<T[]> => {
  const res = await sortKeywordReports(adGroupsList, sortBy)
  return res
}

export const searchPMaxProducts = (
  campaignList: PMaxProductReportType[],
  query: string
) => {
  if (!query) return [...campaignList]

  const lowerQuery = query.toLowerCase()
  return campaignList.filter((item) =>
    item.Title.toLowerCase().includes(lowerQuery)
  )
}

export const sortPMaxProducts = <
  T extends PMaxProductReportType = PMaxProductReportType
>(
  adGroupsList: T[],
  sortBy?: SortByType
): T[] => {
  let sortedList = []

  if (!sortBy?.value || sortBy?.value === "label") {
    sortedList = _sortBy(adGroupsList, (it) => it.Title)
  } else {
    sortedList = _sortBy(adGroupsList, (item) => {
      const value =
        item[(sortBy.value || "Cost") as keyof PMaxProductReportType]
      if (value != null && typeof value === "object" && "Value" in value)
        return value.Value
      return value
    })
  }

  return sortBy?.direction ? sortedList : sortedList.reverse()
}
