const defaults = {
  API_ROOT: process.env.REACT_APP_API_ROOT,
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
}

const config = {
  development: {
    ...defaults,
    ENABLE_OPPORTUNITIES_UPDATE: true,
    REPORT_TABLE_STATUS_FILTER: true,
    ENABLE_ACCOUNT_CAMP_INSIGHTS: true,
    DISABLE_DISCONNECTED_INSIGHTS: false,
    DISABLE_OPPORTUNITIES_ANSWERS: true,
  },

  production: {
    ...defaults,
    REPORT_TABLE_STATUS_FILTER: true,
    ENABLE_OPPORTUNITIES_UPDATE: true,
    DISABLE_DISCONNECTED_INSIGHTS: true,
    ENABLE_ACCOUNT_CAMP_INSIGHTS: true,
    // HIDE_SHOPIFY: true,
    // HIDE_REPORTS: true,
  },
}

export default config[process.env.REACT_APP_BE_ENV] || config.production
