import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useAlert } from "react-alert"
import { useHistory } from "react-router-dom"
import ListingGroup from "@pages/PMax/components/EditAssetGroupPage/ListingGroups/ListingGroups"
import Assets from "@pages/PMax/components/EditAssetGroupPage/Assets/Assets"
import AudienceSignal from "@pages/PMax/components/EditAssetGroupPage/AudienceSignal/AudienceSignal"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import editAssetGroupSchema from "@pages/PMax/components/EditAssetGroupPage/validation/validation"
import {
  AssetGroupEntity,
  CallToActionTypes,
  YoutubeVideoAssetEntity,
} from "@framework/types/manageCampaign"
import { useStore } from "@root/store"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import AssetGroupName from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupName"
import styles from "./EditAssetGroupForm.module.scss"

interface SiteLink {
  description1: string
  description2: string
  linkText: string
  finalUrl: string
}
export interface EditAssetGroupFormValues {
  name: string
  headline: string[]
  longHeadline: string[]
  description: string[]
  finalUrl: string
  logos: string[]
  images: { url: string; name: string; id: number; mimeType: string }[]
  siteLinks: SiteLink[]
  businessName: string
  youtubeVideos: YoutubeVideoAssetEntity[] | null
  callToActionType: CallToActionTypes | null
}
interface EditAssetGroupFormProps {
  formProps: Partial<AssetGroupEntity>
  accountId: number
  assetGroupId: number
}

const EditAssetGroupForm: FC<EditAssetGroupFormProps> = observer((props) => {
  const { pMaxAssetGroupStore, editPMaxCampaignStore } = useStore()
  const [loading, setLoading] = useState(false)
  const [videosForDeletion, setVideosForDeletion] = useState<number[] | null>(
    null
  )
  const alert = useAlert()
  const history = useHistory()
  const {
    updateAssetGroup,
    updateAssetGroupFinalUrl,
    addYouTubeLink,
    deleteYouTubeLink,
    getAssetGroupById,
  } = pMaxAssetGroupStore
  const { campaignId } = editPMaxCampaignStore
  const { formProps, accountId, assetGroupId } = props

  const handleDeleteVideo = (id: number) => {
    setVideosForDeletion((prevVideos) => {
      if (prevVideos) {
        return [...prevVideos, id]
      }
      return [id]
    })
  }

  const handleSubmit = async (values: EditAssetGroupFormValues) => {
    setLoading(true)
    let hasErrors: boolean = false
    const assetGroupRequestBody = {
      textAssets: [
        ...values.headline.map((i: string) => ({
          assetType: "headline",
          content: i,
        })),
        ...values.longHeadline.map((i: string) => ({
          assetType: "long_headline",
          content: i,
        })),
        ...values.description.map((i: string) => ({
          assetType: "description",
          content: i,
        })),
        {
          assetType: "business_name",
          content: values.businessName,
        },
      ],
      siteLinks: values.siteLinks.map((link: SiteLink) => ({
        description1: link.description1,
        description2: link.description2,
        linkText: link.linkText,
        finalUrl: link.finalUrl,
      })),
      callToActionType: values.callToActionType || "UNSPECIFIED",
    }
    const videosRequestBody = values.youtubeVideos?.map((video) => {
      if (video.id) return video
      return {
        url: video.url,
        name: "",
      }
    })

    const uploadLinks = async () => {
      async function* uploadLinksGenerator() {
        let results
        if (videosRequestBody) {
          // eslint-disable-next-line no-restricted-syntax
          for (const video of videosRequestBody) {
            // @ts-ignore
            results = yield addYouTubeLink(accountId, assetGroupId, video)
          }
        }
        return results
      }
      const uploadGen = uploadLinksGenerator()
      let result
      while (!result || !result.done) {
        // eslint-disable-next-line no-await-in-loop
        result = await uploadGen.next(result && result.value)
        if (result?.value?.error && !result.done) {
          hasErrors = true
          alert.error(
            <div>
              <span>Error: {result?.value?.error}</span>
              <span>Url: {result?.value?.url}</span>
            </div>
          )
        }
      }
      if (result.done) {
        deleteLinks()
      }
    }
    uploadLinks()

    const deleteLinks = async () => {
      async function* deleteLinksGenerator() {
        let results
        if (videosForDeletion) {
          // eslint-disable-next-line no-restricted-syntax
          for (const id of videosForDeletion) {
            // @ts-ignore
            results = yield deleteYouTubeLink(accountId, assetGroupId, id)
          }
        }
        return results
      }
      const deleteGen = deleteLinksGenerator()
      let result
      while (!result || !result.done) {
        // eslint-disable-next-line no-await-in-loop
        result = await deleteGen.next(result && result.value)
        if (result?.value?.error && !result.done) {
          hasErrors = true
          alert.error(
            <div>
              <span>Error: {result?.value?.error}</span>
              <span>Id: {result?.value?.id}</span>
            </div>
          )
        }
      }
      if (result.done) {
        updateForm()
      }
    }

    const updateForm = async () => {
      await Promise.all([
        updateAssetGroup(accountId, assetGroupId, assetGroupRequestBody),
        updateAssetGroupFinalUrl(accountId, assetGroupId, {
          finalUrl: values.finalUrl,
          name: values.name,
        }),
      ]).then((result) => {
        const errors = result.filter((res) => res !== "")
        if (errors.length > 0) {
          hasErrors = true
          errors.map((error) =>
            alert.error(<div>Something went wrong: {error}</div>)
          )
        }
        if (errors.length === 0 && !hasErrors) {
          alert.success("Successfully updated")
          getAssetGroupById(accountId, assetGroupId)
          history.replace(
            `/google-ads/${accountId}/campaign/${campaignId}?s=assets`
          )
        }
        setLoading(false)
      })
    }
  }

  const mergedImages = [
    ...(formProps.squareMarketingAssets ?? []),
    ...(formProps.marketingAssets ?? []),
    ...(formProps.portraitMarketingAssets ?? []),
  ]

  const formik = useFormik<EditAssetGroupFormValues>({
    initialValues: {
      headline: formProps.headlineCount || [],
      longHeadline: formProps.longHeadlineCount || [],
      description: formProps.descriptionCount || [],
      businessName: formProps.businessName || "",
      finalUrl: !formProps.finalUrls ? "" : formProps.finalUrls[0],
      name: formProps.name || "",
      siteLinks: formProps.siteLinks || [],
      logos: formProps.logos || [],
      images: mergedImages,
      youtubeVideos: formProps.youtubeVideos || [],
      callToActionType: formProps.callToActionType || "UNSPECIFIED",
    },
    validationSchema: editAssetGroupSchema,
    onSubmit: handleSubmit,
  })

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        className={styles.editAssetGroupForm}
      >
        {loading && (
          <div className={styles.loaderWrapper}>
            <CircleLoader />
          </div>
        )}
        <AssetGroupName />
        <ListingGroup />
        <Assets
          data={formProps}
          mergedImages={mergedImages}
          handleDeleteVideo={handleDeleteVideo}
        />
        <AudienceSignal />
        <Stack direction="row" justify="flex-end">
          <Button
            color="secondary"
            onClick={() =>
              history.replace(
                `/google-ads/${accountId}/campaign/${campaignId}?s=assets`
              )
            }
          >
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Continue
          </Button>
        </Stack>
      </form>
    </FormikProvider>
  )
})

export default EditAssetGroupForm
