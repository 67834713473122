import React, { FC } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import AssetImagesCrop from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar/AssetImagesCrop"
import { PreviewFile } from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/AssetImagesUpload"
import { observer } from "mobx-react-lite"
import styles from "../AssetGroupImagesSidebar/AssetGroupImagesSidebar.module.scss"

interface CropImageModalProps {
  isOpen: boolean
  onClose: () => void
  localFile?: PreviewFile
  file?: { url: string; name: string; id: number; type: string }
  handleCroppedImage: (
    name: string,
    aspect: string,
    croppedImage: any,
    croppedAreaPixels: object,
    id?: number
  ) => void
}

const CropImageModal: FC<CropImageModalProps> = observer(
  ({ onClose, isOpen, localFile, file, handleCroppedImage }) => (
    <Modal
      title="Crop image"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.cropImageModal}
    >
      <Stack align="flex-start" direction="column" justify="center">
        <AssetImagesCrop
          localFile={localFile}
          file={file}
          setCroppedImage={handleCroppedImage}
          onClose={onClose}
        />
      </Stack>
    </Modal>
  )
)

export default CropImageModal
