import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import BaseProgressCircle from "@components/ui/ProgressBar/BaseProgressCircle/BaseProgressCircle"
import { QuadSizeType } from "@utils/enums"
import Typography from "@components/ui/Typography/Typography"
import Radio from "@components/ui/CheckBox/Radio/Radio"
import Label from "@components/ui/CheckBox/Label/Label"
import styles from "./Assets.module.scss"

interface ProgressSubsectionProps {
  images: { url: string; name: string; id: number; mimeType: string }[]
  video: any[]
  products: any[]
  headlines: string[]
  description: string[]
}

const ProgressSubsection: FC<ProgressSubsectionProps> = observer(
  ({ images, video, products, headlines, description }) => {
    const isProducts = products.length > 0
    const isImages = images.length > 0
    const isVideo = video.length > 0
    const isHeadlines = headlines.length > 0
    const isIDescription = description.length > 0

    const progressData: boolean[] = [
      isProducts,
      isImages,
      isVideo,
      isHeadlines,
      isIDescription,
    ]
    const progressValue = (progressData.filter((i) => i).length / 5) * 100
    return (
      <div className={styles.progressSubsection}>
        <div className={styles.progressCircle}>
          <BaseProgressCircle
            value={progressValue}
            size={QuadSizeType.medium}
            percentageShow={false}
          />
          <div>
            <Typography type="body1">Ad strength</Typography>
            {/* <Typography type="body2">Good</Typography> */}
          </div>
        </div>
        <div className={styles.list}>
          <Label text="Images" className={styles.listItem}>
            <Radio
              name="images"
              value="Images"
              checkedIcon="checkbox-circle1"
              checked={isImages}
            />
          </Label>
          <Label text="Video" className={styles.listItem}>
            <Radio
              name="video"
              value="Video"
              checkedIcon="checkbox-circle1"
              checked={isVideo}
            />
          </Label>
          <Label text="Headlines" className={styles.listItem}>
            <Radio
              name="products"
              value="Headlines"
              checkedIcon="checkbox-circle1"
              checked={isHeadlines}
            />
          </Label>
          <Label text="Description" className={styles.listItem}>
            <Radio
              name="products"
              value="Description"
              checkedIcon="checkbox-circle1"
              checked={isIDescription}
            />
          </Label>
          <Label text="Products" className={styles.listItem}>
            <Radio
              name="products"
              value="Products"
              checkedIcon="checkbox-circle1"
              checked={isProducts}
            />
          </Label>
        </div>
      </div>
    )
  }
)

export default ProgressSubsection
