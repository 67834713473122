/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { ListRowProps } from "react-virtualized"
import { useAlert } from "react-alert"

import { WaterfallTable } from "@components/ui/WaterfallTable/WaterfallTable"
import { ID, SortByType } from "@framework/types/types"
import { useStore } from "@store/index"
import AlertMessage from "@components/ui/AlertPopup/AlertTemplate/AlertMessage"
import NoData from "@components/ui/NoData/NoData"
import PlaceholderLoader from "@components/ui/Loader/PlaceholderLoader"
import Loader from "@components/ui/Loader/Loader"
import useSortedKeywords from "@pages/Account/AccountDashboard/components/CampaignInsights/useSortedKeywords"
import { useSearchContext } from "@framework/prototypes/SearchContext"
import useFilteredAdGroupChild from "@pages/Account/AccountDashboard/components/CampaignInsights/useFilteredAdGroupChild"
import { useSelectedAdGroupsContext } from "@framework/prototypes/SelectedAdGroupsContext"
import VirtualizedTable from "./VirtualizedTable"
import KeywordRow from "./KeywordRow"
import {
  defaultKeywordMapper,
  getKeywordReportMapper,
} from "./keywords-table-mapper"

import styles from "./index.module.scss"

const keywordsReportMapper = getKeywordReportMapper()
const negativeKeywordsMapper = defaultKeywordMapper()

type KeywordsTableProps = {
  className?: string
  negativeKeyword?: boolean
}

const KeywordsTable: React.FC<KeywordsTableProps> = observer(
  ({ negativeKeyword, className }) => {
    const alert = useAlert()
    const { manageCampaignStore, accountStore } = useStore()
    const { accountId } = accountStore
    const {
      updateLoading,
      keywordsStore,
      negativeKeywordsStore,
      dateFilter,
      loadKeywordsList,
      loadNegativeKeywordsList,
    } = manageCampaignStore

    const onLoad = negativeKeyword ? loadNegativeKeywordsList : loadKeywordsList
    const store = negativeKeyword ? negativeKeywordsStore : keywordsStore

    const searchContext = useSearchContext()
    const adGroupsContext = useSelectedAdGroupsContext()

    const mapper = negativeKeyword
      ? negativeKeywordsMapper
      : keywordsReportMapper

    const init = async (accountId: number) => {
      const error = await onLoad(accountId, dateFilter)
      if (error)
        alert.error(
          <AlertMessage title="Failed to load report" description={error} />
        )
    }

    React.useEffect(() => {
      if (!accountId || store.error || updateLoading) return
      init(accountId)
    }, [accountId, dateFilter, updateLoading, negativeKeyword])

    const [sortBy, setSortBy] = React.useState<SortByType>({
      value: "",
      direction: true,
    })

    const handleSort = (name: string) => {
      setSortBy((oldSortBy) => ({
        value: name,
        direction: !oldSortBy.direction,
      }))
    }

    const { filteredCollection: filteredKeywords, inProgress: isFiltering } =
      useFilteredAdGroupChild({
        collection: store.data,
        adGroups: adGroupsContext.selectedAdGroups,
      })

    const { sortedKeywords, inProgress: isSorting } = useSortedKeywords({
      keywords: filteredKeywords,
      searchQuery: searchContext.searchQuery,
      sortBy,
    })

    const keywords = sortedKeywords

    const getIndexById = (id: ID) =>
      sortedKeywords.findIndex((it) => it.Id.toString() === id.toString())

    const renderRow = ({ index }: ListRowProps) => {
      const data = sortedKeywords[index]

      const isEditable =
        data.CampaignStatus !== "REMOVED" && data.AdGroupStatus !== "REMOVED"

      return (
        <KeywordRow
          mapper={mapper}
          data={data}
          editable={isEditable}
          className={styles.campaignRow}
        />
      )
    }

    const isLoading =
      updateLoading || store.isLoading || isSorting || isFiltering

    if (keywords.length)
      return (
        <div
          className={clsx(styles.root, className)}
          style={{ minWidth: negativeKeyword ? 1200 : 2040 }}
        >
          <Loader isLoading={isLoading} />

          <WaterfallTable
            labelWidth={420}
            labelSortable
            withTip
            mapper={mapper}
            label="Keyword Name"
            onSort={handleSort}
            sortBy={sortBy}
            rootClassName={styles.flexFullHeight}
            bodyClassName={styles.flexFullHeight}
          >
            <VirtualizedTable
              list={keywords}
              renderRow={renderRow}
              getIndexById={getIndexById}
            />
          </WaterfallTable>
        </div>
      )

    if (store.isLoading) return <PlaceholderLoader />

    if (!adGroupsContext.selectedAdGroups.length)
      return <NoData>No Ad Groups selected</NoData>

    return <NoData>No keywords found</NoData>
  }
)

export default KeywordsTable
