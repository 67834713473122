import React, { FC, MutableRefObject, useEffect, useRef, useState } from "react"
import { Button } from "@components/ui/Button"
// eslint-disable-next-line import/no-extraneous-dependencies
import Cropper from "react-easy-crop"
import getCroppedImg from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/cropImage"
import Stack from "@components/ui/Stack/Stack"
import { PreviewFile } from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/AssetImagesUpload"
import { observer } from "mobx-react-lite"
import styles from "../AssetGroupImagesSidebar/AssetGroupImagesSidebar.module.scss"

interface AssetImagesCropProps {
  localFile?: PreviewFile | null
  file?: { url: string; name: string; id: number; type: string }
  onClose: () => void
  setCroppedImage: (
    name: string,
    aspect: string,
    croppedImage: any,
    croppedAreaPixels: object,
    id?: number
  ) => void
}
export interface AspectRatio {
  wide: { type: "wide_logo"; value: number }
  square: { type: "square"; value: number }
}
const ASPECT_RATIO: AspectRatio = {
  wide: { type: "wide_logo", value: 4 / 1 },
  square: { type: "square", value: 1 / 1 },
}
const AssetImagesCrop: FC<AssetImagesCropProps> = observer(
  ({ localFile, file, setCroppedImage, onClose }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [aspect, setAspect] = useState<AspectRatio[keyof AspectRatio]>(
      ASPECT_RATIO.wide
    )
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState({})
    const localImage: MutableRefObject<string> = useRef<string>(
      localFile ? URL.createObjectURL(localFile.image) : ""
    )
    const image: MutableRefObject<string> = useRef<string>(file ? file.url : "")

    // @ts-ignore
    const onCropComplete = (croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)
    }

    const onCrop = async () => {
      if (localFile) {
        await getCroppedImg(
          localImage.current,
          croppedAreaPixels,
          localFile?.image.type
        ).then((res) => {
          setCroppedImage(localFile.name, aspect.type, res, croppedAreaPixels)
        })
      } else if (file) {
        await getCroppedImg(image.current, croppedAreaPixels, file.type).then(
          (res) => {
            setCroppedImage(
              file.name,
              aspect.type,
              res,
              croppedAreaPixels,
              file.id
            )
          }
        )
      }
    }

    useEffect(
      () => () => {
        if (localImage.current) {
          URL.revokeObjectURL(localImage.current)
          localImage.current = ""
        } else if (image.current) {
          URL.revokeObjectURL(image.current)
          image.current = ""
        }
      },
      []
    )
    return (
      <div>
        <Stack
          className={styles.cropper}
          direction="row"
          justify="flex-start"
          align="center"
        >
          <div className={styles.cropperWrap}>
            <Cropper
              image={image.current ? image.current : localImage.current}
              crop={crop}
              // zoom={zoom}
              aspect={aspect.value}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <Stack
            className={styles.cropperAspectActions}
            direction="column"
            justify="space-between"
          >
            <Button
              color="primary"
              onClick={() => setAspect(ASPECT_RATIO.wide)}
            >
              Landscape: 4 / 1
            </Button>
            <Button
              color="primary"
              onClick={() => setAspect(ASPECT_RATIO.square)}
            >
              Square: 1 / 1
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justify="flex-start"
          className={styles.cropperActions}
        >
          <Button color="primary" onClick={onCrop}>
            Crop
          </Button>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </div>
    )
  }
)

export default AssetImagesCrop
