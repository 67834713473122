import React, { FC, useState, useCallback, useEffect } from "react"
import DocumentDropZone from "@components/ui/DropZone/DropZone"
import AssetImage from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetImage/AssetImage"
import RenameImageModal from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/RenameImageModal"
import { useAlert } from "react-alert"
import DeleteImageModal from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupImagesSidebar/DeleteImageModal"
import { Button } from "@components/ui/Button"
import CropImageModal from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar/CropImageModal"
import { observer } from "mobx-react-lite"
import CircleLoader from "@components/ui/Loader/CircleLoader"
import styles from "../AssetGroupImagesSidebar/AssetGroupImagesSidebar.module.scss"

interface AssetImagesUploadProps {
  onClose: () => void
  onAddToAssets: (PreviewFile: any[]) => void
  updateLoading: boolean
}

const fileSizeValidator = (file: File) => {
  if (file.size > 10000000) {
    return {
      code: "file-too-large",
      message: "File is larger than 10 mb",
    }
  }

  return null
}

export interface PreviewFile {
  isCropped: boolean
  image: File
  preview: string
  key: string
  aspect: string
  name: string
}
const AssetImagesUpload: FC<AssetImagesUploadProps> = observer(
  ({ onClose, onAddToAssets, updateLoading }) => {
    const alert = useAlert()
    const [files, setFiles] = useState<PreviewFile[]>([])
    const [isRenameModalVisible, setIsRenameModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isCropModalVisible, setIsCropModalVisible] = useState(false)
    const [croppedImage, setCroppedImage] = useState<PreviewFile | null>(null)
    const [baseName, setBaseName] = useState<string>("")
    const [imagesForDeletion, setImagesForDeletion] = useState<string[]>([])
    const [isUploading, setIsUploading] = useState<boolean>(false)

    let timeout: ReturnType<typeof setTimeout>
    const handleUploadFiles = useCallback((acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file: File) => ({
          isCropped: false,
          image: file,
          preview: URL.createObjectURL(file),
          key: file.name,
          aspect: "square",
          name: file.name,
        }))
      )
      setIsUploading(true)
      timeout = setTimeout(() => {
        setIsUploading(false)
      }, 1000)
    }, [])

    useEffect(
      () =>
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        () =>
          files.forEach((file) => URL.revokeObjectURL(file.preview))
    )

    useEffect(() => () => clearTimeout(timeout), [])

    const onCloseRenameModal = () => {
      setBaseName("")
      setIsRenameModalVisible(false)
    }
    const onOpenRenameModal = (name: string) => {
      setIsRenameModalVisible(true)
      setBaseName(name)
    }

    const onOpenDeleteModal = (names: string[]) => {
      setIsDeleteModalVisible(true)
      setImagesForDeletion(names)
    }
    const onCloseDeleteModal = () => {
      setImagesForDeletion([])
      setIsDeleteModalVisible(false)
    }

    const onOpenCropModal = (name: string) => {
      const res = files.find((i) => i.name === name)
      if (res) {
        setCroppedImage(res)
        setIsCropModalVisible(true)
      }
    }

    const onCloseCropModal = () => {
      setIsCropModalVisible(false)
      setCroppedImage(null)
    }

    const handleCroppedImage = (
      name: string,
      aspect: string,
      croppedImage: any,
      croppedAreaPixels: object
    ) => {
      const editingFile = files.find(
        (file) => file.name === name
      ) as PreviewFile
      let croppedFile: PreviewFile
      if (!editingFile.isCropped) {
        croppedFile = {
          ...editingFile,
          name,
          image: croppedImage,
          preview: URL.createObjectURL(croppedImage),
          isCropped: true,
          aspect,
        }
      } else {
        croppedFile = {
          ...editingFile,
          name,
          image: croppedImage,
          preview: URL.createObjectURL(croppedImage),
          aspect,
        }
      }
      setFiles((prevState: PreviewFile[]) => {
        const res: any[] = prevState.map((file) => {
          if (file.name === name) {
            return croppedFile
          }
          return file
        })
        return res
      })
      setIsCropModalVisible(false)
    }

    const handleRenameImage = (name: string, baseName: string) => {
      const nameIsDuplicated = files.find((i) => i.image.name === name)
      if (nameIsDuplicated) {
        alert.error("Name already in use")
      } else {
        setFiles((prevState: PreviewFile[]) => {
          const res: PreviewFile[] = prevState.map((file) => {
            if (file.name === baseName) {
              return {
                ...file,
                name,
                image: new File([file.image], name, { type: file.image.type }),
                preview: file.preview,
                key: file.key,
              }
            }
            return file
          })
          return res
        })
        alert.success("Image has been renamed")
        setIsRenameModalVisible(false)
        setBaseName("")
      }
    }

    const handleDeleteImage = () => {
      setFiles((prevState: PreviewFile[]) => {
        const result: PreviewFile[] | [] = prevState.filter(
          (file) => !imagesForDeletion.includes(file.name)
        )
        return result
      })
      setImagesForDeletion([])
      setIsDeleteModalVisible(false)
    }

    const handleCheckedImage = (name: string) => {
      setImagesForDeletion((prevState) => {
        if (prevState.includes(name))
          return [...prevState.filter((img) => img !== name)]
        return [...prevState, name]
      })
    }

    const handleAddToAssets = () => {
      onAddToAssets(files)
    }

    return (
      <div>
        <DocumentDropZone
          onDrop={handleUploadFiles}
          className={styles.dropZone}
          requirement="Choose an allowed file type: png, jpg, jpeg; allowed file size: 10 Mb"
          accepted={["image/png", "image/jpeg", "image/jpg"]}
          maxFiles={20}
          validator={fileSizeValidator}
        />

        {updateLoading && (
          <div className={styles.loader}>
            <CircleLoader />
          </div>
        )}
        <div className={styles.imagesList}>
          {files.map((file) => (
            <AssetImage
              src={file.preview}
              name={file.name}
              onLocalRename={onOpenRenameModal}
              onLocalCrop={onOpenCropModal}
              onLocalDelete={onOpenDeleteModal}
              checked={imagesForDeletion.includes(file.name)}
              onLocalCheck={handleCheckedImage}
              isLoading={isUploading}
              key={`${file.key}`}
            />
          ))}
        </div>

        <RenameImageModal
          baseName={baseName}
          isOpen={isRenameModalVisible}
          onClose={onCloseRenameModal}
          onLocalRename={handleRenameImage}
        />

        <DeleteImageModal
          isOpen={isDeleteModalVisible}
          onClose={onCloseDeleteModal}
          onLocalDelete={handleDeleteImage}
        />

        {croppedImage && (
          <CropImageModal
            isOpen={isCropModalVisible}
            onClose={onCloseCropModal}
            localFile={croppedImage}
            handleCroppedImage={handleCroppedImage}
          />
        )}

        <div className={styles.sidebarFooter}>
          {/* Add disabled state to the button !!! */}
          <div className={styles.sidebarFooterLeft}>
            <Button color="primary" onClick={handleAddToAssets}>
              Add to Asset Group
            </Button>
            <Button
              className={styles.footerButton}
              color="secondary"
              onClick={handleDeleteImage}
            >
              Delete
            </Button>
          </div>
          <Button
            className={styles.footerButton}
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
)

export default AssetImagesUpload
