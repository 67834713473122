import React from "react"
import clsx from "clsx"

import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Icon from "@components/ui/Icon/Icon"
import ToggleButton from "@components/ui/CheckBox/ToggleButton/ToggleButton"
import Label from "@components/ui/CheckBox/Label/Label"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import { observer } from "mobx-react-lite"
import useAddKeywordsModal from "../CampaignTable/useAddKeywordsModal"

export type TableHeaderProps = {
  className?: string
  isNegativeKeyword?: boolean
  onKeywordsSwitch?: (value: boolean) => void
}

export const KeywordsTableControl: React.FC<TableHeaderProps> = observer(
  ({ isNegativeKeyword = false, onKeywordsSwitch, className }) => {
    const modal = useAddKeywordsModal()

    return (
      <>
        <Templates.Header
          className={clsx(className)}
          left={
            <Label>
              <Stack direction="row" gutter="8">
                <Typography weight="semibold">Regular keywords</Typography>

                <ToggleButton
                  checked={isNegativeKeyword}
                  onChange={
                    onKeywordsSwitch
                      ? () => onKeywordsSwitch(!isNegativeKeyword)
                      : undefined
                  }
                />

                <Typography weight="semibold">Negative Keywords</Typography>
              </Stack>
            </Label>
          }
          right={
            <Button
              onClick={modal.open}
              before={<Icon name="plus" />}
              variant="outlined"
              size="big"
            >
              Add Keywords
            </Button>
          }
        />

        {modal.Modal}
      </>
    )
  }
)

export default KeywordsTableControl
