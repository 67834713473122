import React from "react"
import useToggle from "@framework/hooks/useToggle"
import { Collapsable } from "@components/ui/Collapsible/Collapsable"
import { CollapsibleHeader } from "@components/ui/Collapsible/CollapsibleHeader"
import Typography from "@components/ui/Typography/Typography"
import Icon from "@components/ui/Icon/Icon"
import { Button } from "@components/ui/Button"
import { FieldArray, useFormikContext } from "formik"
import LimitLengthTextField from "@pages/PaidSearchChannel/ManageProfile/components/AdCopyManagement/EditCopySidebar/LimitLengthTextField"
import { EditAssetGroupFormValues } from "@pages/PMax/components/EditAssetGroupPage/EditAssetGroupForm"
import FormTextField from "@framework/prototypes/FormTextField"
import { observer } from "mobx-react-lite"
import styles from "./Assets.module.scss"

interface SitelinkSectionProps {
  num: number
  onRemove: () => void
}
const SitelinkSection = observer((props: SitelinkSectionProps) => {
  const { num, onRemove } = props
  return (
    <div className={styles.inputsSection}>
      <Button
        color="default"
        after={<Icon name="trash" key="trash" />}
        variant="ghost"
        as="button"
        onClick={onRemove}
      >
        Sitelink {num}
      </Button>

      <LimitLengthTextField
        className={styles.textField}
        name={`siteLinks[${num}].linkText`}
        maxChars={25}
        placeholder="Sitelink text"
      />
      <LimitLengthTextField
        className={styles.textField}
        name={`siteLinks[${num}].description1`}
        maxChars={35}
        placeholder="Description line 1 (optional)"
      />
      <LimitLengthTextField
        className={styles.textField}
        name={`siteLinks[${num}].description2`}
        maxChars={35}
        placeholder="Description line 2 (optional)"
      />
      <FormTextField
        className={styles.textField}
        name={`siteLinks[${num}].finalUrl`}
        placeholder="Final URL"
      />
    </div>
  )
})

const Sitelinks = observer(() => {
  const toggle = useToggle()
  const { values, setValues, setFieldValue } =
    useFormikContext<EditAssetGroupFormValues>()

  const handleRemoveField = (i: number) => {
    if (values.siteLinks?.length === 1) return
    const siteLinksArray = [...(values.siteLinks as [])]
    siteLinksArray?.splice(i, 1)
    setValues({
      ...values,
      siteLinks: siteLinksArray,
    })
  }

  const handleAddField = () => {
    if (!toggle.isOpened) {
      toggle.setOpened(true)
    }
    const siteLinksData =
      values.siteLinks && values.siteLinks.length ? values.siteLinks : []
    setFieldValue(
      "siteLinks",
      [
        ...siteLinksData,
        {
          description1: "",
          description2: "",
          linkText: "",
          finalUrl: "",
        },
      ],
      false
    )
  }

  return (
    <div className={styles.section}>
      <CollapsibleHeader
        label="Sitelinks"
        onClick={toggle.handleToggle}
        open={toggle.isOpened}
        type="h2"
        rootClassName={styles.collapsibleHeader}
      />
      <div className={styles.textIcon}>
        <Icon name="attachment" />
        <Typography type="subtitle" color="gray">
          Account-level sitelinks
        </Typography>
      </div>
      <Button
        color="primary"
        before={<Icon name="plus" />}
        variant="ghost"
        className={styles.buttonIcon}
        onClick={handleAddField}
      >
        Sitelinks
      </Button>
      <Collapsable open={toggle.isOpened}>
        <FieldArray
          name="siteLinks"
          render={(formikProps) => (
            <div>
              {values.siteLinks && values.siteLinks.length ? (
                values.siteLinks.map((item, i: number) => (
                  <div key={`siteLinks-${i + 1}`}>
                    <SitelinkSection
                      num={i}
                      onRemove={() => {
                        handleRemoveField(i)
                      }}
                    />
                  </div>
                ))
              ) : (
                <div />
              )}
              {formikProps.form.errors.siteLinks && (
                <Typography type="body2" color="red">
                  {formikProps.form.errors.siteLinks
                    ? "Please, fill the required fields."
                    : ""}
                </Typography>
              )}
            </div>
          )}
        />
      </Collapsable>
    </div>
  )
})

export default Sitelinks
