import React, { FC } from "react"
import Modal from "@components/ui/Modal/Modal"
import Stack from "@components/ui/Stack/Stack"
import { Button } from "@components/ui/Button"
import Typography from "@components/ui/Typography/Typography"
import styles from "./AssetGroupImagesSidebar.module.scss"

interface DeleteImageModalProps {
  isOpen: boolean
  onClose: () => void
  onDelete?: () => void
  onLocalDelete?: () => void
}

const DeleteImageModal: FC<DeleteImageModalProps> = ({
  onClose,
  isOpen,
  onDelete,
  onLocalDelete,
}) => {
  const handleDelete = () => {
    if (onDelete) {
      onDelete()
    } else if (onLocalDelete) {
      onLocalDelete()
    }
  }
  return (
    <Modal
      title="Delete image?"
      isOpen={isOpen}
      onClose={onClose}
      className={styles.deleteImageModal}
    >
      <Stack align="stretch">
        <Typography type="body1">
          Are you sure you want to delete this image?
        </Typography>
        <Stack direction="row" justify="flex-end">
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleDelete}>
            Delete
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default DeleteImageModal
