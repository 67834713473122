import React from "react"
import { SortByType } from "@framework/types/types"
import {
  searchKeywords,
  sortKeywordReportsAsync,
} from "@pages/ManageCampaign/utils"
import { KeywordEntityType, KeywordReportType } from "@framework/types/account"

const emptyArray: KeywordReportType[] = []

export const useSortedKeywords = (config: {
  keywords: KeywordEntityType[] | null
  sortBy: SortByType
  searchQuery?: string
}) => {
  const { keywords, sortBy, searchQuery } = config

  const [inProgress, setInProgress] = React.useState(false)

  const [sortedKeywords, setSortedKeywords] =
    React.useState<KeywordReportType[]>(emptyArray)

  const update = async (
    campaignList: KeywordEntityType[],
    sortBy?: SortByType,
    searchQuery?: string
  ) => {
    setInProgress(true)

    const filtered = await searchCampaigns(campaignList, searchQuery)
    const res = await sortCampaigns(filtered, sortBy)

    setSortedKeywords(res)
    setInProgress(false)
  }

  React.useEffect(() => {
    update(keywords ?? emptyArray, sortBy, searchQuery)
  }, [keywords, sortBy, searchQuery])

  return { sortedKeywords, inProgress }
}

const sortCampaigns = async (
  keywords: KeywordReportType[],
  sortBy?: SortByType
) => {
  if (keywords.length === 0) return keywords

  const res = await sortKeywordReportsAsync(keywords, sortBy)
  return res
}

const searchCampaigns = async (
  keywords: KeywordEntityType[],
  searchQuery: string = ""
) => {
  const res = await searchKeywords(keywords, searchQuery)
  return res
}

export default useSortedKeywords
