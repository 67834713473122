import React from "react"
import ReactDOM from "react-dom"
import clsx from "clsx"

import useDelayedValue from "@framework/hooks/useDelayedValue"

import styles from "./BlurContainer.module.scss"

export interface SidebarContainerProps {
  blur: boolean
  className?: string
}

export const BlurContainer: React.FC<SidebarContainerProps> = ({
  blur,
  children,
  className,
}) => {
  const isMounted = useDelayedValue(blur, 200) || blur

  if (!isMounted) return null
  return ReactDOM.createPortal(
    <div
      className={clsx(
        styles.root,
        blur ? styles.visible : styles.hidden,
        className
      )}
    >
      {children}
    </div>,
    document.body
  )
}

export default BlurContainer
