import React from "react"
import { observer } from "mobx-react-lite"
import sum from "lodash/sum"
import Box from "@components/ui/Box/Box"
import Typography from "@components/ui/Typography/Typography"
import Stack from "@components/ui/Stack/Stack"
import Chip from "@components/ui/Chip/Chip"
import Image from "@components/ui/Image/Image"
import { AssetGroupEntity } from "@framework/types/manageCampaign"
import { countedFormat } from "@utils/stringUtils"
import { ID } from "@framework/types/types"
import ImageTopper from "@components/ui/Image/Topper"
import Footer from "./Footer"
import Header from "./Header"
import { AudienceSignalTable } from "./AudienceSignalTable"

import styles from "./AssetCard.module.scss"

export interface AssetCardProps {
  campaignId: ID
  data: AssetGroupEntity
  onSelect?: (id: number) => void
  isSelected?: (id: number) => boolean
}

const AssetCard: React.FC<AssetCardProps> = observer(
  ({ campaignId, data, isSelected, onSelect }) => {
    const assets = extractAssets(data)
    const moreAssetsNumber = assets.length > 4 ? assets.length - 4 : 0
    const images = [
      ...(data.marketingImages ?? []),
      ...(data.squareMarketingImages ?? []),
    ]

    const visibleImages = images.length > 5 ? images.slice(0, 4) : images
    const lastImage = images.length > 5 ? images[5] : null
    const imageOverflow = images.length > 5 ? images.length - 5 : 0

    return (
      <Box className={styles.root}>
        <Header
          data={data}
          selected={isSelected?.(data.id)}
          onSelect={() => onSelect?.(data.id)}
        />

        <Stack className={styles.body} gutter="16" align="stretch">
          <Stack gutter="8" align="stretch">
            <Stack gutter="4">
              <Typography type="h4">Assets</Typography>
              <Typography type="body2">{renderSummary(data)}</Typography>
            </Stack>

            <div className={styles.images}>
              {visibleImages.map((imageSrc) => (
                <Image src={imageSrc} className={styles.image} key={imageSrc} />
              ))}
              {lastImage ? (
                <ImageTopper
                  className={styles.image}
                  message={`+${imageOverflow}`}
                >
                  <Image src={lastImage} key="last-image" />
                </ImageTopper>
              ) : null}
            </div>

            <Stack gutter="4" direction="row" align="center">
              {assets.slice(0, 4).map((group) => (
                <Chip type="outlined" title={group} rounded key={group}>
                  {group}
                </Chip>
              ))}
              {moreAssetsNumber > 0 ? (
                <Typography color="black70Color">
                  +{moreAssetsNumber}
                </Typography>
              ) : null}
            </Stack>
          </Stack>

          {/* <Stack gutter="8">
            <Typography type="h4">Listing group</Typography>
            <Stack gutter="4" direction="row">
              {listingGroups.map((group) => (
                <Chip type="outlined" rounded key={group}>
                  {group}
                </Chip>
              ))}
            </Stack>
          </Stack> */}

          <Stack gutter="8">
            <Typography type="h4">Audience signal</Typography>
            <AudienceSignalTable data={data} />
          </Stack>
        </Stack>

        <Footer campaignId={campaignId} data={data} />
      </Box>
    )
  }
)

export default AssetCard

const extractAssets = (data: AssetGroupEntity) => [
  ...(data.headlineCount ?? []),
  ...(data.longHeadlineCount ?? []),
  ...(data.descriptionCount ?? []),
]

const renderSummary = (data: AssetGroupEntity) =>
  [
    renderCounted(
      sum([
        countNullableArray(data.marketingImages),
        countNullableArray(data.squareMarketingImages),
      ]),
      "image"
    ),

    renderCounted(countNullableArray(data.logos), "logo"),

    renderCounted(countNullableArray(data.youtubeVideos), "video"),

    renderCounted(countNullableArray(data.headlineCount), "headline"),

    renderCounted(countNullableArray(data.longHeadlineCount), "long headline"),

    renderCounted(countNullableArray(data.descriptionCount), "description"),
  ].reduce((acc, it, idx, list) => {
    if (!acc) return it
    if (!it) return acc
    if (idx < list.length - 1) return `${acc}, ${it}`
    return `${acc} and ${it} added to this asset`
  }, "")

const renderCounted = (count: number, unit: string) =>
  count > 0 ? `${count} ${countedFormat(unit, count)}` : ""

const countNullableArray = (array: any[] | null) => array?.length ?? 0
