import React, { FC, useEffect, useState } from "react"
import SidebarContainer from "@components/ui/Modal/SidebarContainer"
import { observer } from "mobx-react-lite"
import AssetImagesList from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar/AssetImagesList"
import AssetImagesUpload, {
  PreviewFile,
} from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupLogosSidebar/AssetImagesUpload"
import useURLSearchParams from "@framework/hooks/useURLSearchParams"
import { useStore } from "@root/store"
import { useAlert } from "react-alert"
import { convertBase64 } from "@services/utils"
import styles from "../AssetGroupImagesSidebar/AssetGroupImagesSidebar.module.scss"

interface AssetImagesSidebarProps {
  isOpen: boolean
  onClose: () => void
}
export interface ImageType {
  url: string
  name: string
  id: number
  type: string
}
type SidebarContentType = "list" | "upload"
type SidebarContentProps = {
  content: SidebarContentType
  onAddImage: () => void
  logos: ImageType[] | []
  onDeleteImage: (ids: number[]) => void
  onRenameImage: (name: string, imageId: number) => void
  onClose: () => void
  onAddToAssets: (files: PreviewFile[]) => void
  accountId: number
  assetGroupId: number
  updateLoading: boolean
}

const SidebarContent: FC<SidebarContentProps> = observer(
  ({
    accountId,
    assetGroupId,
    content,
    onAddImage,
    logos,
    onDeleteImage,
    onRenameImage,
    onClose,
    onAddToAssets,
    updateLoading,
  }) => {
    useEffect(() => {}, [content])
    switch (content) {
      case "list":
        return (
          <AssetImagesList
            onAddImage={onAddImage}
            images={logos}
            onDeleteImage={onDeleteImage}
            onRenameImage={onRenameImage}
            onClose={onClose}
            accountId={accountId}
            assetGroupId={assetGroupId}
          />
        )
      case "upload":
        return (
          <AssetImagesUpload
            onAddToAssets={onAddToAssets}
            onClose={onClose}
            updateLoading={updateLoading}
          />
        )
      default:
        return <div />
    }
  }
)

const AssetLogosSidebar: FC<AssetImagesSidebarProps> = observer(
  ({ isOpen, onClose }) => {
    const alert = useAlert()
    const params = useURLSearchParams()
    const assetGroupId: number = Number(params.get("assetGroupId"))
    const { pMaxAssetGroupStore, accountStore } = useStore()
    const {
      getAssetGroupById,
      deleteAssetGroupImage,
      updateAssetGroupImageName,
      uploadAssetGroupImages,
      updateError,
      updateLoading,
      data,
    } = pMaxAssetGroupStore
    const accountId = accountStore.getAccountId()
    const [content, setContent] = useState<SidebarContentType>("list")
    const [uploadingErrors, setUploadingErrors] = useState<
      { name: string; error: string }[]
    >([])
    const [deletionErrors, setDeletionErrors] = useState<
      { id: number; error: string }[]
    >([])
    const [minOneDeletedImage, setMinOneDeletedImage] = useState<boolean>(false)
    const [minOneUploadedImage, setMinOneUploadedImage] =
      useState<boolean>(false)
    const [uploadingFinished, setUploadingFinished] = useState(false)
    const [deletingFinished, setDeletingFinished] = useState(false)

    useEffect(() => {
      getAssetGroupById(accountId, assetGroupId)
    }, [])

    const logos = [
      ...(data.logoAssets ?? []),
      ...(data.landscapeLogoAssets ?? []),
    ]

    const createImagesList = (
      imagesList: { url: string; name: string; id: number; mimeType: string }[]
    ): ImageType[] => {
      // RegExp for replacing "mimeType": ... field with "type": "image/png, .../jpeg, .../jpg" field.
      const regex = /^IMAGE_(JPEG|PNG|JPG)$/
      const res: ImageType[] = imagesList.map((img) => ({
        url: img.url,
        name: img.name,
        id: img.id,
        type: img.mimeType.replace(
          regex,
          (match, p1) => `image/${p1.toLowerCase()}`
        ),
      }))
      return res
    }

    const handleUploadToAssets = async (files: PreviewFile[]) => {
      async function addImagesHandler(callback: any) {
        async function* generateSequence() {
          let results
          // eslint-disable-next-line no-restricted-syntax
          for (const file of files) {
            let base64
            if (file.isCropped) {
              const img: File = new File([file.image], file.name, {
                type: file.image.type,
              })
              // eslint-disable-next-line no-await-in-loop
              base64 = await convertBase64(img)
            } else {
              // eslint-disable-next-line no-await-in-loop
              base64 = await convertBase64(file.image)
            }
            // @ts-ignore
            const base64Url = base64.replace("data:", "").replace(/^.+,/, "")
            // @ts-ignore
            results = yield uploadAssetGroupImages(accountId, assetGroupId, {
              aspectRatio: file.aspect,
              name: file.name,
              data: base64Url,
              type: "logo",
            })
          }
          return results
        }
        const generator = generateSequence()
        let result
        while (!result || !result.done) {
          // eslint-disable-next-line no-await-in-loop
          result = await generator.next(result && result.value)
          callback(result)
        }
      }
      addImagesHandler(
        (
          result: {
            value: { name: string; error: string }
            done: boolean
          } | null
        ) => {
          if (result?.value && !result.done) {
            setUploadingErrors((prevState) => [
              ...prevState,
              { name: result.value.name, error: result.value.error },
            ])
          } else if (!result?.value && !result?.done) {
            setMinOneUploadedImage(true)
          }
          if (result?.done) {
            setUploadingFinished(true)
          }
        }
      )
    }

    useEffect(() => {
      if (uploadingFinished) {
        if (uploadingErrors.length && !minOneUploadedImage) {
          uploadingErrors.map((err) =>
            alert.error(
              <div>
                <div>File: {err.name}</div>
                <div>Error: {err.error}</div>
              </div>
            )
          )
        }
        if (minOneUploadedImage && uploadingErrors.length) {
          uploadingErrors.map((err) =>
            alert.error(
              <div>
                <div>File: {err.name}</div>
                <div>Error: {err.error}</div>
              </div>
            )
          )
          alert.success("Logo uploaded")
          handleOnClose()
          getAssetGroupById(accountId, assetGroupId)
        }
        if (!uploadingErrors.length) {
          alert.success("Logos uploaded")
          handleOnClose()
          getAssetGroupById(accountId, assetGroupId)
        }

        setUploadingFinished(false)
        setMinOneUploadedImage(false)
        setUploadingErrors([])
      }
    }, [uploadingFinished])

    useEffect(() => {
      if (deletingFinished) {
        if (deletionErrors.length) {
          deletionErrors.map((err) =>
            alert.error(
              <div>
                <div>Image id: {err.id}</div>
                <div>Error: {err.error}</div>
              </div>
            )
          )
        }
        if (minOneDeletedImage) alert.success("Images deleted")
        setDeletionErrors([])
        setMinOneDeletedImage(false)
        setDeletingFinished(false)
        getAssetGroupById(accountId, assetGroupId)
      }
    }, [deletingFinished])

    const handleDeleteImage = async (ids: number[]) => {
      async function* deleteImagesGen() {
        let results
        // eslint-disable-next-line no-restricted-syntax
        for (const id of ids) {
          // @ts-ignore
          results = yield deleteAssetGroupImage(accountId, assetGroupId, id)
        }
        return results
      }
      const gen = deleteImagesGen()
      const callback = (result: any) => {
        if (result.value.error && !result.done) {
          setDeletionErrors((prevState) => [
            ...prevState,
            { error: result.value.error, id: result.value.id },
          ])
        }
        if (result.value === true) setMinOneDeletedImage(true)
        if (result.done) {
          setDeletingFinished(true)
          getAssetGroupById(accountId, assetGroupId)
        }
      }
      let result
      while (!result || !result.done) {
        // eslint-disable-next-line no-await-in-loop
        result = await gen.next(result && result.value)
        callback(result)
      }
    }

    const handleRenameImage = async (name: string, imageId: number) => {
      await updateAssetGroupImageName(accountId, assetGroupId, imageId, {
        name,
      }).then((res) => {
        if (updateError) {
          alert.error(updateError)
        } else {
          alert.success("Image has been renamed")
          getAssetGroupById(accountId, assetGroupId)
        }
      })
    }

    const handleOnClose = () => {
      setContent("list")
      onClose()
    }
    const handleAddImage = () => {
      setContent("upload")
    }
    return (
      <SidebarContainer isOpen={isOpen} onClose={onClose}>
        <aside className={styles.assetImageUploadSidebar}>
          <SidebarContent
            content={content}
            onAddImage={handleAddImage}
            onDeleteImage={handleDeleteImage}
            onRenameImage={handleRenameImage}
            logos={createImagesList(logos)}
            onClose={handleOnClose}
            onAddToAssets={handleUploadToAssets}
            accountId={accountId}
            assetGroupId={assetGroupId}
            updateLoading={updateLoading}
          />
        </aside>
      </SidebarContainer>
    )
  }
)

export default AssetLogosSidebar
