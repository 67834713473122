export const numberWithCommas = (x: string | number = 0, fixed: number = 0) =>
  Number(x)
    .toFixed(fixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const randomNumber = (min: number, range: number) =>
  Math.floor(Math.random() * range) + min

export const randomNumbersArray = (length: number, max: number) =>
  initArrayByLength(length).map(() => randomNumber(0, max))

export const initArrayByLength = (length: number) =>
  Array.from(Array(length).keys())

export const initArray = <T = unknown>(
  length: number,
  callback: (idx: number) => T
): T[] => Array.from(Array(length).keys()).map(callback)

export default {
  numberWithCommas,
  randomNumber,
  randomNumbersArray,
  initArrayByLength,
}
