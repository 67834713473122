import React, { FC, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { FormikProvider, useFormik } from "formik"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"
import createAssetGroupSchema from "@pages/PMax/components/CreateAssetGroupPage/validation/validation"
import AssetGroupName from "@pages/PMax/components/EditAssetGroupPage/Assets/AssetGroupName"
import { Button } from "@components/ui/Button"
import Stack from "@components/ui/Stack/Stack"
import Assets from "@pages/PMax/components/CreateAssetGroupPage/Assets"
import { ID } from "@framework/types/types"
import { CreateAssetGroupRequestType } from "@services/account-campaign.service"
import Typography from "@components/ui/Typography/Typography"
import styles from "./CreateAssetGroupForm.module.scss"

export interface CreateAssetGroupFormValues {
  name: string
  headline: string[]
  longHeadline: string[]
  description: string[]
  finalUrl: string
  businessName: string
}
interface CreateAssetGroupFormProps {
  campaignId: ID
  accountId: ID
  onCreate: (
    accountId: ID,
    campaignId: ID,
    data: CreateAssetGroupRequestType
  ) => Promise<string | null>
}

const CreateAssetGroupForm: FC<CreateAssetGroupFormProps> = observer(
  ({ campaignId, accountId, onCreate }) => {
    const history = useHistory()
    const alert = useAlert()
    const handleSubmit = async (values: CreateAssetGroupFormValues) => {
      const assetGroupRequestBody = {
        textAssets: [
          ...values.headline.map((i: string) => ({
            assetType: "headline",
            content: i,
          })),
          ...values.longHeadline.map((i: string) => ({
            assetType: "long_headline",
            content: i,
          })),
          ...values.description.map((i: string) => ({
            assetType: "description",
            content: i,
          })),
          {
            assetType: "business_name",
            content: values.businessName,
          },
        ],
        name: values.name,
        finalUrl: values.finalUrl,
      }

      await onCreate(accountId, campaignId, assetGroupRequestBody).then(
        (result) => {
          if (result) {
            alert.error(<div>Something went wrong: {result}</div>)
          } else {
            alert.success("Successfully created")
            history.replace(
              `/google-ads/${accountId}/campaign/${campaignId}?s=assets`
            )
          }
        }
      )
    }

    const formik = useFormik<CreateAssetGroupFormValues>({
      initialValues: {
        headline: [],
        longHeadline: [],
        description: [],
        businessName: "",
        finalUrl: "",
        name: "",
      },
      validationSchema: createAssetGroupSchema,
      onSubmit: handleSubmit,
    })

    const isErrors = Object.keys(formik.errors).length > 0
    useEffect(() => {
      if (isErrors) {
        alert.error(
          <div>
            {Object.entries(formik.errors).map(([key, value]) => (
              <div className={styles.formValidationError}>
                <Typography type="h4">{key}</Typography>
                <Typography type="body2">{value}</Typography>
              </div>
            ))}
          </div>
        )
      }
    }, [formik.errors, isErrors])

    return (
      <article>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <AssetGroupName />
            <Assets
              data={{
                headlineCount: formik.values.headline,
                longHeadlineCount: formik.values.longHeadline,
                descriptionCount: formik.values.description,
              }}
            />
            <Stack direction="row" justify="flex-end">
              <Button
                color="secondary"
                onClick={() =>
                  history.replace(
                    `/google-ads/${accountId}/campaign/${campaignId}?s=assets`
                  )
                }
              >
                Cancel
              </Button>
              <Button color="primary" type="submit" disabled={isErrors}>
                Continue
              </Button>
            </Stack>
          </form>
        </FormikProvider>
      </article>
    )
  }
)

export default CreateAssetGroupForm
